import React from "react"
import PropTypes from "prop-types"

import FooterStyled from "./styled/FooterStyled"
import FooterSection from "./styled/FooterSection"
import FooterSmallprint from "./styled/FooterSmallprint"

const Footer = ({ children, revision, browser }) => (
  <FooterStyled>
    <FooterSection>{children}</FooterSection>
    <FooterSmallprint>
      <span>Revision: {revision}</span>
      <span>Browser: {browser}</span>
    </FooterSmallprint>
  </FooterStyled>
)

export default Footer

Footer.propTypes = {
  children: PropTypes.any,
}
