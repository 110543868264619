import styled from "styled-components"
import { Link } from "gatsby"
import breakpoints from "../../../theme/breakpoints"

const LinkStyled = styled(Link)`
  display: table-cell;
  background: transparent;
  color: #fff;
  text-decoration: none;
  vertical-align: bottom;
  padding-left: 1rem;
  &:hover {
    color: #cfd8dd;
  }
  font-size: 0.8rem;
  @media (min-width: ${breakpoints.sm}) {
    font-size: 0.9rem;
  }
  @media (min-width: ${breakpoints.md}) {
    font-size: 1rem;
  }
  line-height: 1.5;
`

export default LinkStyled
