import styled from "styled-components"
import theme from "../../../theme"

const FooterSmallprint = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: small;
  line-height: 1.5;
`

export default FooterSmallprint
