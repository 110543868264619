import styled from "styled-components"
import theme from "../../../theme"

const FooterSection = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: ${theme.sectionWidth};
  margin-left: auto;
  margin-right: auto;
  padding: 0 0.5rem;
  height: auto;
  width: 100%;
`

export default FooterSection
