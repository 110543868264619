import styled from "styled-components"
import { space } from "styled-system"
import theme from "../../theme"
import breakpoints from "../../theme/breakpoints"

export const H1 = styled.h1`
  font-size: ${theme.fontSizes[2]};
  line-height: ${theme.lineHeights[2]};
  margin-top: 1.2777778rem;
  margin-bottom: 1.2777778rem;
  @media (min-width: ${breakpoints.sm}) {
    font-size: ${theme.fontSizes[1]};
    line-height: ${theme.lineHeights[1]};
    margin-top: 1.2777778rem;
    margin-bottom: 2.5555556rem;
  }
  ${space}
`
export const H2 = styled.h2`
  font-size: ${theme.fontSizes[3]};
  line-height: ${theme.lineHeights[3]};
  margin-top: 1.2777778rem;
  margin-bottom: 0rem;
  @media (min-width: ${breakpoints.sm}) {
    font-size: ${theme.fontSizes[2]};
    line-height: ${theme.lineHeights[2]};
    margin-top: 1.2777778rem;
    margin-bottom: 1.2777778rem;
  }
  ${space}
`

export const H3 = styled.h3`
  font-size: ${theme.fontSizes[3]};
  line-height: ${theme.lineHeights[3]};
  margin-top: 1.2777778rem;
  margin-bottom: 0rem;
  ${space}
`

export const H4 = styled.h4`
  font-size: ${theme.fontSizes[4]};
  line-height: ${theme.lineHeights[4]};
  margin-top: 1.2777778rem;
  margin-bottom: 0rem;
  ${space}
`
export const H5 = styled.h5`
  font-size: ${theme.fontSizes[5]};
  line-height: ${theme.lineHeights[5]};
  margin-top: 1.2777778rem;
  margin-bottom: 0rem;
  ${space}
`
export const P = styled.p`
  margin-top: 0rem;
  margin-bottom: 1.2777778rem;
  ${space}
`

export const Ul = styled.ul`
  margin-top: 0rem;
  margin-bottom: 1.2777778rem;
  ${space}
`

export const Ol = styled.ol`
  margin-top: 0rem;
  margin-bottom: 1.2777778rem;
  > li {
    font-weight: bold;
  }
  ${space}
`

export const Pre = styled.pre`
  margin-top: 0rem;
  margin-bottom: 1.2777778rem;
  ${space}
`

export const Table = styled.table`
  margin-top: 0rem;
  margin-bottom: 1.2777778rem;
  ${space}
`

export const Blockquote = styled.blockquote`
  margin-top: 0rem;
  margin-bottom: 1.2777778rem;
  ${space}
`

export const Hr = styled.hr`
  border: 1px solid;
  margin: -1px 0;
  ${space}
`

export const Div = styled.div`
  ${space}
`
